<template>
  <div
    class="abrir_caixa row justify-content-center d-flex align-items-baseline titulo_dash"
  >
    <div class="col-md-5">
      <div class="image_div position-relative mx-auto">
        <img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
      </div>
    </div>
    <div class="col-md-7 pr-0">
      <div class="name">
        <p class="title">
          Olá,
          <br />
          <strong>{{ user.displayName }}!</strong>
        </p>
        <p class="texto mb-0">
          Enquanto organizamos as suas coisas por aqui, informe, por favor, como
          está o
          <strong>caixa hoje.</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["qtdImpress"],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
.input_default {
  color: #2474b2;
}
.title strong {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
}
.texto {
  color: #868686 !important;
  font-size: 13px;
}
.title {
  color: #868686 !important;
}
.name {
  text-align: left;
}
.image_div {
  overflow: hidden;
  width: 85px;
  overflow: hidden;
  height: 85px;
  border-radius: 50%;
  border: 5px solid #2474b2;
}

.image_div .img_user {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: top;
}
</style>
