<template>
  <div>
    <div class="modal fade" id="modalCaixa" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_custom" role="document">
        <div class="modal-content container">
          <div class="modal-body px-4 py-4">
            <ValidationObserver
              ref="formCategory"
              class="row"
              tag="form"
              @submit.prevent="sendUnidade()"
            >
              <div class="col-md-12">
                <topo :qtdImpress.sync="qtdImpress" />
              </div>
              <components :is='caixa' :totalValue.sync="totalValue" @changeValueNota='valueRetirada' />
              <div class="px-0 col-md-5 mx-auto">
                <button
                  type="submit"
                  class="btn_default px-2 py-2"
                  :disabled="disabled"
                  @click.prevent="fecharCaixa() "
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="disabled"
                  ></span>
                  {{!disabled ? `( ${$options.filters.currency(totalValue)} )` : ''}}
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from '@/api_system'
import newCaixa from './modal/newCaixa'
import retiraCaixa from './modal/retiradaCaixa.vue'
import topo from './modal/topo'
export default {
  components: { newCaixa, topo, retiraCaixa },
  props: ['id'],
  data () {
    return {
      disabled: false,
      totalValue: 0,
      caixa: 'newCaixa',
      qtdImpress: 0,
      idCaixaValue: 0
    }
  },
  methods: {
    fecharCaixa () {
      let idCaixa = this.$parent.id
      let id = sessionStorage.getItem('idCaixa')
      let numberId = Number(id)
      this.idCaixaValue = numberId
      if (this.caixa === 'newCaixa') {
        this.disabled = true
        HTTP.post(
          'services/app/Cashier/ClosingCashier?finished=true',
          {
            endValue: this.totalValue,
            endPrints: 0,
            id: numberId
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        ).then(() => {
          this.disabled = false
          this.caixa = 'retiraCaixa'
          this.totalValue = 0
         
          this.$emit('getParent')
          this.$notify({
            group: 'erros',
            type: 'sucess',
            text: `<i class="icon ion-close-circled"></i>Caixa Fechado`
          })
        })
      } else {
        this.retirada()
      }
    },
    retirada () {
      let id = sessionStorage.getItem('idCaixa')
      let numberId = Number(id)

      HTTP.post(
        `services/app/Cashier/CashWithdrawal`,
        {
          cashierId: numberId,
          value: this.totalValue
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      ).then(() => {
        // eslint-disable-next-line no-undef
        $('#modalCaixa').modal('hide')
        window.location.reload()
         sessionStorage.removeItem('idCaixa')
      }).catch(err => console.log(err))
    },
    valueRetirada (newValue) {
      this.totalValue = newValue
    }
  }
}
</script>

<style scoped>
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.modal_custom {
  max-width: 450px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2;
  font-weight: 500;
}

.input_default {
  height: 45px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}

.caixa_cp {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
/* width */
.caixa_cp::-webkit-scrollbar {
  width: 5px;
  background: #e3e3e3;
}

/* Track */
.caixa_cp::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.caixa_cp::-webkit-scrollbar-thumb {
  background: #868686;
  border-radius: 0px;
}
.btn_default {
  width: 100%;
  border-radius: 7px;
  font-weight: 500;
  border: 1px solid #2474b2;
  background-color: #2474b2;
  color: #ffff;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
  transition: all 0.2s linear;
  font-size: 14px;
}

.btn_default:hover {
  width: 100%;
  border-radius: 7px;
  font-weight: 500;
  border: 1px solid #f38235;
  background-color: #f38235;
  transition: all 0.2s linear;
  color: #ffff;
}

</style>
